import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { EMAIL_IDS_UPDATED, REQUEST_ERROR } from '../../../../../../utils/messages';

const EditFooterEmailIdPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    let { email_id } = useParams();

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [email, setEmail] = useState('');
    const [isEmailError, setIsEmailError] = useState(false);
    const [emailErrorTxt, setEmailErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const getThisFooterEmailIdHandler = async () => {
        try {
            const getThisFooterEmailIdReq = await axios.get(`${API_BASE_URL}footer/email-ids/${email_id}`);
            console.log(getThisFooterEmailIdReq);
            setEmail(getThisFooterEmailIdReq.data.email_id);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getThisFooterEmailIdHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(email == ''){
            setEmailErrorTxt('Lable is required');
            setIsEmailError(true);
            return;
        }
        else{
            setEmailErrorTxt('');
            setIsEmailError(false);
        }

        const data = {
            email_id: email
        }

        console.log(data);

        try {
            const updateFooterEmailIdReq = await axios.patch(`${API_BASE_URL}footer/email-ids/${email_id}`, data, config);
            notifySuccess(EMAIL_IDS_UPDATED);
            navigate('/dashboard/general/footer/email-ids');
            LOGGER &&  console.log(updateFooterEmailIdReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="email ID's" description={routeText} action={() => navigate("/dashboard/general/footer/email-ids")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <TextInput lable="email id" isMandatory={true} isError={isEmailError} error={emailErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={email} placeholder="Please Enter The Email ID" onChange={(e) => setEmail(e.target.value)} />
                        </TextInput>
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditFooterEmailIdPage