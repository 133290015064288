import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import axios from 'axios';
import moment from 'moment';

import Stats from '../../../components/dashboard/common/Stats';
import Heading from '../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../components/dashboard/common/table/HeadingItem';
import RowItem from '../../../components/dashboard/common/table/RowItem';
import Table from '../../../components/dashboard/common/table/Table';
import TableRow from '../../../components/dashboard/common/table/TableRow';
import PlainContainer from '../../../components/general/containers/PlainContainer';
import Section from '../../../components/general/containers/Section';
import TableButton from '../../../components/dashboard/common/table/TableButton';
import RowIcon from '../../../components/dashboard/common/table/RowIcon';
import RecentStoreOrders from '../../../components/dashboard/home/RecentStoreOrders';
import NewCustomers from '../../../components/dashboard/home/NewCustomers';
import { API_BASE_URL, LOGGER } from '../../../config';
import { REQUEST_ERROR } from '../../../utils/messages';
import { notifyError } from '../../../utils/toastify';

const Home = () => {
  let navigate = useNavigate();

  const admin = useSelector((state) => state.admin.value);

	const config = {
		headers: {
			'Authorization': admin.access_token
		}
	}

	const [appointmentRequests, setAppointmentRequests] = useState([]);

	const [callbackRequests, setCallbackRequests] = useState([]);

	const [contactRequests, setContactRequests] = useState([]);

	const [dealershipRequests, setDealershipRequests] = useState([]);

	const getRequestStats = async () => {
		try{
			const getRequestsReq = await axios.get(`${API_BASE_URL}appointment-request/limit`, config);
			console.log(getRequestsReq);
			if (getRequestsReq.status === 200) {
				setAppointmentRequests(getRequestsReq.data.data);
			}
		}
		catch(error) {
			notifyError(REQUEST_ERROR);
			LOGGER &&  console.log(error);
		}
	}

	const getCallbackStats = async () => {
		try{
			const getRequestsReq = await axios.get(`${API_BASE_URL}callback-request/limit`, config);
			console.log(getRequestsReq);
			if (getRequestsReq.status === 200) {
				setCallbackRequests(getRequestsReq.data.data);
			}
		}
		catch(error) {
			notifyError(REQUEST_ERROR);
			LOGGER &&  console.log(error);
		}
	}

	const getContactRequests = async () => {
		try{
			const getRequestsReq = await axios.get(`${API_BASE_URL}contact-request/limit`, config);
			console.log(getRequestsReq);
			if (getRequestsReq.status === 200) {
				setContactRequests(getRequestsReq.data.data);
			}
		}
		catch(error) {
			notifyError(REQUEST_ERROR);
			LOGGER &&  console.log(error);
		}
	}

	const getDealershipRequests = async () => {
		try{
			const getRequestsReq = await axios.get(`${API_BASE_URL}dealership/request/limit`, config);
			console.log(getRequestsReq);
			if (getRequestsReq.status === 200) {
				setDealershipRequests(getRequestsReq.data.data);
			}
		}
		catch(error) {
			notifyError(REQUEST_ERROR);
			LOGGER &&  console.log(error);
		}
	}

	useEffect(() => {
		getRequestStats();
		getCallbackStats();
		getContactRequests();
		getDealershipRequests();
	}, []);

	return (
		<>
			<Stats auth={config} />

			<PlainContainer styles={{marginTop: '20px', width: '100%'}}>
				<PlainContainer styles={{width: '50%', paddingRight: '10px'}}>
					<Section heading="appointment requests" description="List of recently received appointment requests" action={() => navigate("/dashboard/leads/appointment-requests")} actionLable="view all" showAction={true}>
						<Table>
							<Heading>
								<HeadingItem size="1" isIcon={false} icon="" lable={`SL NO`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`name`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`phone no.`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`received on`} />
							</Heading>
							<PlainContainer styles={{width: '100%'}}>
								{
									appointmentRequests.map((req, i) => {
										return (
											<TableRow key={i}>
												<RowItem size="1" lable={i+1} isCenter={true} hasChildren={false}></RowItem>
												<RowItem size="2" lable={req.name} isCenter={false} hasChildren={false}></RowItem>
												<RowItem size="2" lable={req.phone_number} isCenter={false} hasChildren={false}></RowItem>
												{/* <RowItem size="2" lable={moment(req.date_time).format('MMMM Do YYYY, h:mm a')} isCenter={true} hasChildren={false}></RowItem> */}
												<RowItem size="2" lable={moment(req.date_time).format('DD/MM/YYYY')} isCenter={true} hasChildren={false}></RowItem>
											</TableRow>
										)
									})
								}
							</PlainContainer>
						</Table>
					</Section>
				</PlainContainer>
				<PlainContainer styles={{width: '50%', paddingLeft: '10px'}}>
					<Section heading="callback requests" description="List of recently received callback requests" action={() => navigate("/dashboard/leads/callback-requests")} actionLable="view all" showAction={true}>
						<Table>
							<Heading>
								<HeadingItem size="1" isIcon={false} icon="" lable={`SL NO`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`name`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`phone no.`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`received on`} />
								<HeadingItem size="1" isIcon={false} icon="" lable={``} />
							</Heading>
							<PlainContainer styles={{width: '100%'}}>
								{
									callbackRequests.map((req, i) => {
										return (
											<TableRow key={i}>
												<RowItem size="1" lable={i+1} isCenter={true} hasChildren={false}></RowItem>
												<RowItem size="2" lable={req.name} isCenter={false} hasChildren={false}></RowItem>
												<RowItem size="2" lable={req.phone_number} isCenter={false} hasChildren={false}></RowItem>
												<RowItem size="2" lable={moment(req.date_time).format('DD/MM/YYYY')} isCenter={true} hasChildren={false}></RowItem>
												<RowItem size="1" lable="2" isCenter={false} hasChildren={true}>
													<RowIcon name="fa-solid fa-eye" action={() => navigate(`/dashboard/leads/callback-requests/details/${req.id}`)} styles={{cursor: 'pointer'}} />
												</RowItem>
											</TableRow>
										)
									})
								}
							</PlainContainer>
						</Table>
					</Section>
				</PlainContainer>
			</PlainContainer>
			<PlainContainer styles={{marginTop: '20px', width: '100%'}}>
				<PlainContainer styles={{width: '50%', paddingRight: '10px' }}>
					<Section heading="contact requests" description="List of recently received contact requests" action={() => navigate("/dashboard/leads/contact-enquiries")} actionLable="view all" showAction={true}>
						<Table>
							<Heading>
								<HeadingItem size="1" isIcon={false} icon="" lable={`SL NO`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`name`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`phone no.`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`received on`} />
								<HeadingItem size="1" isIcon={false} icon="" lable={``} />
							</Heading>
							<PlainContainer styles={{width: '100%'}}>
								{
									contactRequests.map((req, i) => {
										return (
											<TableRow key={i}>
												<RowItem size="1" lable={i+1} isCenter={true} hasChildren={false}></RowItem>
												<RowItem size="2" lable={req.name} isCenter={false} hasChildren={false}></RowItem>
												<RowItem size="2" lable={req.phone_number} isCenter={false} hasChildren={false}></RowItem>
												{/* <RowItem size="2" lable={moment(req.date_time).format('MMMM Do YYYY, h:mm a')} isCenter={true} hasChildren={false}></RowItem> */}
												<RowItem size="2" lable={moment(req.date_time).format('DD/MM/YYYY')} isCenter={true} hasChildren={false}></RowItem>
												<RowItem size="1" lable="2" isCenter={false} hasChildren={true}>
													<RowIcon name="fa-solid fa-eye" action={() => navigate(`/dashboard/leads/contact-enquiries/details/${req.id}`)} styles={{cursor: 'pointer'}} />
												</RowItem>
											</TableRow>
										)
									})
								}
							</PlainContainer>
						</Table>
					</Section>
				</PlainContainer>

				<PlainContainer styles={{width: '50%', paddingLeft: '10px' }}>
					<Section heading="dealership requests" description="List of recently received dealership requests" action={() => navigate("/dashboard/leads/dealership-requests")} actionLable="view all" showAction={true}>
						<Table>
							<Heading>
								<HeadingItem size="1" isIcon={false} icon="" lable={`SL NO`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`name`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`phone no.`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`received on`} />
								<HeadingItem size="1" isIcon={false} icon="" lable={``} />
							</Heading>
							<PlainContainer styles={{width: '100%'}}>
								{
									dealershipRequests.map((req, i) => {
										return (
											<TableRow key={i}>
												<RowItem size="1" lable={i+1} isCenter={true} hasChildren={false}></RowItem>
												<RowItem size="2" lable={req.name} isCenter={false} hasChildren={false}></RowItem>
												<RowItem size="2" lable={req.phone_number} isCenter={false} hasChildren={false}></RowItem>
												{/* <RowItem size="2" lable={moment(req.date_time).format('MMMM Do YYYY, h:mm a')} isCenter={true} hasChildren={false}></RowItem> */}
												<RowItem size="2" lable={moment(req.date_time).format('DD/MM/YYYY')} isCenter={true} hasChildren={false}></RowItem>
												<RowItem size="1" lable="2" isCenter={false} hasChildren={true}>
													<RowIcon name="fa-solid fa-eye" action={() => navigate(`/dashboard/leads/dealership-requests/details/${req.id}`)} styles={{cursor: 'pointer'}} />
												</RowItem>
											</TableRow>
										)
									})
								}
							</PlainContainer>
						</Table>
					</Section>
				</PlainContainer>
			</PlainContainer>
			
		</>  
	);
};

export default Home;