import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DetailSection from '../../../../../../components/dashboard/common/detailSection/DetailSection';
import DetailSectionContent from '../../../../../../components/dashboard/common/detailSection/DetailSectionContent';
import DetailSectionRow from '../../../../../../components/dashboard/common/detailSection/DetailSectionRow';

import NoDataContainer from '../../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../../../../../../config';

import { PRODUCT_VARIANT_CREATION_FAILED, PRODUCT_VARIANT_DELETED, REQUEST_ERROR } from '../../../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';

const HomePartnerPortalsHeadingsPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [homePartnerPortals, setHomePartnerPortals] = useState({});
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getHomePartnerPortalsHandler = async () => {
        try {
            const getHomePartnerPortalsReq = await axios.get(`${API_BASE_URL}home/partner-portal/headings`);
            console.log(getHomePartnerPortalsReq.data);
            setHomePartnerPortals(getHomePartnerPortalsReq.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getHomePartnerPortalsHandler();
    }, []);

    return (
        <>
            <Section heading="partner portals heading" description={routeText} action={() => navigate("/dashboard/pages/home/partner-portals")} actionLable="back" showAction={true}>
                <PlainContainer type="fullwidth">
                    <DetailSection lable="partner portals heading" hasPrimaryAction={true} lablePrimaryAction={`edit`} primaryAction={() => navigate("/dashboard/pages/home/partner-portals/headings/edit")} hasSecondaryAction={false}>
                        <DetailSectionContent hasPadding={true}>
                            <DetailSectionRow lable={`section heading`} value={homePartnerPortals.section_heading} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`heading bold`} value={homePartnerPortals.heading_bold} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`heading regular`} value={homePartnerPortals?.heading_regular} isImage={false} isIcon={false} hasChildren={false} />
                        </DetailSectionContent>
                    </DetailSection>
                </PlainContainer>
            </Section>
        </>
    )
}

export default HomePartnerPortalsHeadingsPage