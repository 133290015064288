import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Heading from '../../../../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../../../../components/dashboard/common/table/HeadingItem';
import RowItem from '../../../../../../components/dashboard/common/table/RowItem';
import RowImage from '../../../../../../components/dashboard/common/table/RowImage';
import Table from '../../../../../../components/dashboard/common/table/Table';
import TableButton from '../../../../../../components/dashboard/common/table/TableButton';
import TableRow from '../../../../../../components/dashboard/common/table/TableRow';
import NoDataContainer from '../../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../../../../../../config';

import { PRODUCT_VARIANT_CREATION_FAILED, PRODUCT_VARIANT_DELETED, REQUEST_ERROR } from '../../../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';

const HomePartnerPortalsListPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [portals, setPortals] = useState([]);
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getPortalsHandler = async () => {
        try {
            const getPortalsReq = await axios.get(`${API_BASE_URL}home/partner-portal/portal`);
            console.log(getPortalsReq.data);
            setPortals(getPortalsReq.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getPortalsHandler();
    }, []);

    const deleteItem = async (id) => {
        try {
            const deletePortalReq = await axios.delete(`${API_BASE_URL}home/partner-portal/portal/${id}`, config);
            //notifySuccess(CLIENT_DELETED);
            getPortalsHandler();
            LOGGER &&  console.log(deletePortalReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    return (
        <>
            <Section heading="partner portals list" description={routeText} action={() => navigate("/dashboard/pages/home/partner-portals/portals/create")} actionLable="add new" showAction={true}>
                <PlainContainer type="fullwidth">
                    <Table>
                        <Heading>
                            <HeadingItem size="1" isIcon={false} icon="" lable={`sl no`} />
                            <HeadingItem size="2" isIcon={false} icon="" lable={`name`} />
                            <HeadingItem size="4" isIcon={false} icon="" lable={`link`} />
                            <HeadingItem size="1" isIcon={false} icon="" lable={`image`} />
                            <HeadingItem size="3" isIcon={false} icon="" lable={`action`} />
                        </Heading>
                        <PlainContainer styles={{width: '100%'}}>
                            {
                                portals.map((portal, i) => {
                                    return (
                                        <TableRow key={portal.id}>
                                            <RowItem size="1" lable={i+1} isCenter={true} hasChildren={false}></RowItem>
                                            <RowItem size="2" lable={portal.name} isCenter={false} hasChildren={false}></RowItem>
                                            <RowItem size="4" lable={portal.url} isCenter={false} hasChildren={false}></RowItem>
                                            <RowItem size="1" isCenter={false} hasChildren={true}>
                                                <RowImage src={portal.image_url} />
                                            </RowItem>
                                            <RowItem size="3" lable="3" isCenter={false} hasChildren={true}>
                                                <TableButton styles={{width: 'calc(50% - 5px)', marginRight: '10px'}} action={() => navigate(`edit/${portal.id}`)} type="primary">edit</TableButton>
                                                <TableButton styles={{width: 'calc(50% - 5px)'}} action={() => deleteItem(portal.id)} type="danger">delete</TableButton>
                                            </RowItem>
                                        </TableRow>
                                    )
                                })
                            }
                            
                        </PlainContainer>
                    </Table>
                </PlainContainer>
            </Section>
        </>
    )
}

export default HomePartnerPortalsListPage