import React from 'react';
import { Link } from 'react-router-dom';

const PageLinkButton = ({lable, to}) => {
    return (
        <Link to={to} className="pageLinkButton">
            <div className="pageLinkButtonLable">
                <p className="pageLinkButtonLableTxt">{lable}</p>
            </div>
            <div className="pageLinkButtonIcon center">
                <i className="fa-solid fa-pencil pageLinkButtonIco"></i>
            </div>
        </Link>
    )
}

export default PageLinkButton