import React from 'react'

const DetailSectionRowHTML = ({lable, children}) => {
    return (
        <div className="detailSectionRow">
            <div className="detailSectionRowLable">
                <p className="detailSectionRowLableTxt">{lable}</p>
            </div>
            <div className="detailSectionRowSeperator">
                <p className="detailSectionRowSeperatorTxt">:</p>
            </div>
            <div className="detailSectionRowContent" dangerouslySetInnerHTML={{__html: children}}></div>
        </div>
    )
}

export default DetailSectionRowHTML