import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { REQUEST_ERROR, SOCIAL_MEDIA_UPDATED } from '../../../../../../utils/messages';

const EditHeaderSocialMediaLinksPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [youtube, setYoutube] = useState('');

    const [isFacebookError, setIsFacebookError] = useState(false);
    const [isInstagramError, setIsInstagramError] = useState(false);
    const [isWhatsappError, setIsWhatsappError] = useState(false);
    const [isYoutubeError, setIsYoutubeError] = useState(false);

    const [facebookErrorTxt, setFacebookErrorTxt] = useState("");
    const [instagramErrorTxt, setInstagramErrorTxt] = useState("");
    const [whatsappErrorTxt, setWhatsappErrorTxt] = useState("");
    const [youtubeErrorTxt, setYoutubeErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const getHeaderSocialMediaHandler = async () => {
        try {
            const getHeaderSocialMediaReq = await axios.get(`${API_BASE_URL}header/social-media`);
            console.log(getHeaderSocialMediaReq);
            setFacebook(getHeaderSocialMediaReq.data.facebook_url);
            setInstagram(getHeaderSocialMediaReq.data.instagram_url);
            setWhatsapp(getHeaderSocialMediaReq.data.whatsapp_no);
            setYoutube(getHeaderSocialMediaReq.data.youtube_url);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getHeaderSocialMediaHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(facebook == ''){
            setFacebookErrorTxt('Facebook URL is required');
            setIsFacebookError(true);
            return;
        }
        else{
            setFacebookErrorTxt('');
            setIsFacebookError(false);
        }

        if(instagram == ''){
            setInstagramErrorTxt('Instagram URL is required');
            setIsInstagramError(true);
            return;
        }
        else{
            setInstagramErrorTxt('');
            setIsInstagramError(false);
        }

        if(whatsapp == ''){
            setWhatsappErrorTxt('WhatsApp No. is required');
            setIsWhatsappError(true);
            return;
        }
        else{
            setWhatsappErrorTxt('');
            setIsWhatsappError(false);
        }

        if(youtube == ''){
            setYoutubeErrorTxt('WhatsApp No. is required');
            setIsYoutubeError(true);
            return;
        }
        else{
            setYoutubeErrorTxt('');
            setIsYoutubeError(false);
        }

        const data = {
            facebook_url: facebook,
            instagram_url: instagram,
            whatsapp_no: whatsapp,
            youtube_url: youtube
        }

        try {
            const updateHeaderSocialMediaReq = await axios.patch(`${API_BASE_URL}header/social-media/edit`, data, config);
            notifySuccess(SOCIAL_MEDIA_UPDATED);
            navigate('/dashboard/general/header/social-media-links');
            LOGGER &&  console.log(updateHeaderSocialMediaReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="social media links" description={routeText} action={() => navigate("/dashboard/general/header/social-media-links")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <SplitContainer type="fullwidth">
                            <TextInput lable="facebook URL" isMandatory={true} isError={isFacebookError} error={facebookErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={facebook} placeholder="Please Enter The Facebook URL" onChange={(e) => setFacebook(e.target.value)} />
                            </TextInput>
                            <TextInput lable="instagram URL" isMandatory={true} isError={isInstagramError} error={instagramErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={instagram} placeholder="Please Enter The Instagram URL" onChange={(e) => setInstagram(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <SplitContainer type="fullwidth">
                            <TextInput lable="whatsapp no." isMandatory={true} isError={isWhatsappError} error={whatsappErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={whatsapp} placeholder="Please Enter The WhatsApp No." onChange={(e) => setWhatsapp(e.target.value)} />
                            </TextInput>
                            <TextInput lable="youtube URL" isMandatory={true} isError={isYoutubeError} error={youtubeErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={youtube} placeholder="Please Enter The Youtube URL" onChange={(e) => setYoutube(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditHeaderSocialMediaLinksPage