import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Heading from '../../../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../../../components/dashboard/common/table/HeadingItem';
import RowItem from '../../../../../components/dashboard/common/table/RowItem';
import Table from '../../../../../components/dashboard/common/table/Table';
import TableButton from '../../../../../components/dashboard/common/table/TableButton';
import TableRow from '../../../../../components/dashboard/common/table/TableRow';

import DetailSection from '../../../../../components/dashboard/common/detailSection/DetailSection';
import DetailSectionContent from '../../../../../components/dashboard/common/detailSection/DetailSectionContent';
import DetailSectionRow from '../../../../../components/dashboard/common/detailSection/DetailSectionRow';

import NoDataContainer from '../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../../../../../config';

import { OPENING_DELETED, REQUEST_ERROR } from '../../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';

const BlogCategoriesPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [blogCategories, setBlogCategories] = useState([]);
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getBlogCategoriesHandler = async () => {
        try {
            const getBlogCategoriesReq = await axios.get(`${API_BASE_URL}blog/category`);
            console.log(getBlogCategoriesReq.data);
            setBlogCategories(getBlogCategoriesReq.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getBlogCategoriesHandler();
    }, []);

    const deleteThisCategoryHandler = async (id) => {
        try {
            const deleteThisCategoryHandlerReq = await axios.delete(`${API_BASE_URL}blog/category/${id}`, config);
            console.log(deleteThisCategoryHandlerReq.data);
            getBlogCategoriesHandler();
            notifySuccess(OPENING_DELETED);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    return (
        <>
            <Section heading="Categories" description={routeText} action={() => navigate("/dashboard/pages/blogs")} actionLable="back" showAction={true}>
                <PlainContainer type="fullwidth">
                    <DetailSection lable="List of Categories" hasPrimaryAction={true} lablePrimaryAction={`create`} primaryAction={() => navigate("/dashboard/pages/blogs/categories/create")} hasSecondaryAction={false}>
                        <DetailSectionContent hasPadding={true}>
                            <Table>
                                <Heading>
                                    <HeadingItem size="1" isIcon={false} icon="" lable={`sl no`} />
                                    <HeadingItem size="4" isIcon={false} icon="" lable={`name`} />
                                    <HeadingItem size="3" isIcon={false} icon="" lable={`action`} />
                                </Heading>
                                <PlainContainer styles={{width: '100%'}}>
                                    {
                                        blogCategories.map((category, i) => {
                                            return (
                                                <TableRow tabIndex={category.id}>
                                                    <RowItem size="1" lable={i+1} isCenter={true} hasChildren={false}></RowItem>
                                                    <RowItem size="4" lable={category.name} isCenter={false} hasChildren={false}></RowItem>
                                                    <RowItem size="3" lable="3" isCenter={false} hasChildren={true}>
                                                        <TableButton styles={{width: 'calc(50% - 5px)', marginRight: '10px'}} action={() => navigate(`edit/${category.id}`)} type="primary">edit</TableButton>
                                                        <TableButton styles={{width: 'calc(50% - 5px)'}} action={() => deleteThisCategoryHandler(category.id)} type="danger">delete</TableButton>
                                                    </RowItem>
                                                </TableRow>
                                            )
                                        })
                                    }
                                    
                                </PlainContainer>
                            </Table>
                        </DetailSectionContent>
                    </DetailSection>
                </PlainContainer>
            </Section>
        </>
    )
}

export default BlogCategoriesPage