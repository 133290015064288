import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Section from '../../../../components/general/containers/Section';
import PageLinkButton from '../../../../components/general/forms/PageLinkButton';
import PageLinkSection from '../../../../components/general/forms/PageLinkSection';

const HeaderPage = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    return (
        <Section heading="header" description={routeText} action={""} showAction={false}>
            <PageLinkSection>
                <PageLinkButton lable="action banner" to="action-banner" />
                <PageLinkButton lable="logo" to="logo" />
                <PageLinkButton lable="contact details" to="contact-details" />
                <PageLinkButton lable="social media links" to="social-media-links" />
            </PageLinkSection>
        </Section>
    )
}

export default HeaderPage