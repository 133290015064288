import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import SelectInput from '../../../../../../components/general/forms/SelectInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import FileInput from '../../../../../../components/general/forms/FileInput';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { REQUEST_ERROR, CATEGORY_ADDED } from '../../../../../../utils/messages';

const AddProductPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(0);

    const [title, setTitle] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [description, setDescription] = useState('');
    const [keywords, setKeywords] = useState('');
    const [slug, setSlug] = useState('');
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [imageID, setImageID] = useState('');

    const [isCategoryError, setIsCategoryError] = useState(false);
    const [isTitleError, setIsTitleError] = useState(false);
    const [isSeoDescriptionError, setIsSeoDescriptionError] = useState(false);
    const [isDescriptionError, setIsDescriptionError] = useState(false);
    const [isKeywordsError, setIsKeywordsError] = useState(false);
    const [isSlugError, setIsSlugError] = useState(false);
    const [isNameError, setIsNameError] = useState(false);
    const [isImageError, setIsImageError] = useState(false);

    const [categoryErrorTxt, setCategoryErrorTxt] = useState("");
    const [titleErrorTxt, setTitleErrorTxt] = useState("");
    const [seoDescriptionErrorTxt, setSeoDescriptionErrorTxt] = useState("");
    const [descriptionErrorTxt, setDescriptionErrorTxt] = useState("");
    const [keywordsErrorTxt, setKeywordsErrorTxt] = useState("");
    const [slugErrorTxt, setSlugErrorTxt] = useState("");
    const [nameErrorTxt, setNameErrorTxt] = useState("");
    const [imageErrorTxt, setImageErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);
    
    const getProductCategoriesHandler = async () => {
        try {
            const getProductCategoriesReq = await axios.get(`${API_BASE_URL}product/category`);
            console.log(getProductCategoriesReq.data);
            if(getProductCategoriesReq.data.length != 0){

                let temp_categories = [];

                getProductCategoriesReq.data.map(category => {
                    let data = {
                        id: category.id,
                        name: category.category.name
                    }
                    temp_categories.push(data);
                });

                setCategories(temp_categories);
            }
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getProductCategoriesHandler();
    }, []);

    const slugChanged = async (data) => {
        setSlug(data);
        try{
            const slugStatusReq = await axios.get(`${API_BASE_URL}product/product/check-slug?slug=${data}`, config);
            if(slugStatusReq.status === 200){
                setSlugErrorTxt('');
                setIsSlugError(false);
            }
        }
        catch(error){
            if(error.response.status === 403){
                setSlugErrorTxt('SEO URL Unavailable');
                setIsSlugError(true);
            }
            else{
                setSlugErrorTxt("Something Went Wrong. Try Again.");
                setIsSlugError(true);
            }
        }
    }

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(title == ''){
            setTitleErrorTxt('Title is required');
            setIsTitleError(true);
            return;
        }
        else{
            setTitleErrorTxt('');
            setIsTitleError(false);
        }

        if(slug == ''){
            setSlugErrorTxt('Page URL is required');
            setIsSlugError(true);
            return;
        }
        else{
            setSlugErrorTxt('');
            setIsSlugError(false);
        }

        if(name == ''){
            setNameErrorTxt('Name is required');
            setIsNameError(true);
            return;
        }
        else{
            setNameErrorTxt('');
            setIsNameError(false);
        }

        if(category == 0){
            setCategoryErrorTxt('Product category is required');
            setIsCategoryError(true);
            return;
        }
        else{
            setCategoryErrorTxt('');
            setIsCategoryError(false);
        }

        if(description == ''){
            setDescriptionErrorTxt('Description is required');
            setIsDescriptionError(true);
            return;
        }
        else{
            setDescriptionErrorTxt('');
            setIsDescriptionError(false);
        }

        if(imageID == ''){
            setImageErrorTxt('Image is required');
            setIsImageError(true);
            return;
        }
        else{
            setImageErrorTxt('');
            setIsImageError(false);
        }

        const data = {
            title: title,
            seo_description: description,
            keywords: keywords,
            slug: slug,
            name: name,
            product_category: category,
            description: description,
            image: imageID,
            keywords: keywords,
        }

        try {
            const createProductReq = await axios.post(`${API_BASE_URL}product/product`, data, config);
            notifySuccess(CATEGORY_ADDED);
            navigate('/dashboard/pages/products/products');
            LOGGER &&  console.log(createProductReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="Products" description={routeText} action={() => navigate("/dashboard/pages/products/products")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="SEO title" isMandatory={true} isError={isTitleError} error={titleErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={title} placeholder="Please Enter The SEO Title" onChange={(e) => setTitle(e.target.value)} />
                            </TextInput>
                            <TextInput lable="SEO URL" isMandatory={true} isError={isSlugError} error={slugErrorTxt}>
                                <input type="text" className="formStepInputTxt" value={slug} placeholder="Please Enter The SEO Page URL" onChange={(e) => slugChanged(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <TextArea lable="SEO description" rows={3} isMandatory={false} placeholder="Please Enter The SEO Description" value={seoDescription} onchange={(data) => setSeoDescription(data)} isError={isSeoDescriptionError} error={seoDescriptionErrorTxt} />
                        <TextArea lable="keywords" rows={3} isMandatory={false} placeholder="Please Enter The Keywords" value={keywords} onchange={(data) => setKeywords(data)} isError={isKeywordsError} error={keywordsErrorTxt} />
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="name" isMandatory={true} isError={isNameError} error={nameErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={name} placeholder="Please Enter The Name" onChange={(e) => setName(e.target.value)} />
                            </TextInput>
                            <SelectInput lable="product category" capitalize={true} isMandatory={false} placeholder="Please Select The Category" value={category} onchange={(data) => setCategory(data)} isError={false} error={``} options={categories} />
                        </SplitContainer>
                        
                        <TextArea lable="description" rows={3} isMandatory={false} placeholder="Please Enter The Description" value={description} onchange={(data) => setDescription(data)} isError={isDescriptionError} error={descriptionErrorTxt} />
                        <FileInput lable="featured image" isMandatory={true} value={imageID} preview={image} onchange={(data) => {setImageID(data); LOGGER &&  console.log(data)}} isError={isImageError} error={imageErrorTxt} reset={resetAttachment} />
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default AddProductPage