import React, { useState } from 'react';

import PlainContainer from '../../general/containers/PlainContainer';
import Text from '../../general/typography/Text';
import PopUp from './PopUp';

import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_BASE_URL } from '../../../config';

const FileUploadPopUp = ({lable, primaryLable, primaryAction, secondaryLable, secondaryAction, secondaryType, getUploadedImageUrl}) => {

    const admin = useSelector((state) => state.admin.value);

    const [imagePreview, setImagePreview] = useState('');
    const [isImageValid, setIsImageValid] = useState(true);

    const [formImage, setImage] = useState();

    const handleImageAsFile = (e) => {
        e.preventDefault();
        setIsImageValid(e.target.files[0]);
        const image = e.target.files[0];
        setImage(image);
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                setImagePreview(e.target.result);
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
           setImagePreview(null); 
        }
    }

    const uploadImage = () => {
        if(imagePreview != ''){
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': admin.access_token
                }
            };

            var formData = new FormData();
            formData.append(`image_url`, formImage);

            axios.post(`${API_BASE_URL}media`, formData, config, {
                onUploadProgress: (ProgressEvent) => {
                    let progress = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
                }
            }).then(res => {
                const status = res.status;
                if (status === 200) {
                    // console.log(res.data.url);
                    getUploadedImageUrl(
                        {
                            url: res.data.url, 
                            id: res.data.id
                        }
                    );
                }
            }).catch(err => {
                // console.log(err);
            });
        }
        else{
            alert('please select an image');
        }
    }

    return (
        <PopUp lable={lable} primaryLable={primaryLable} primaryAction={uploadImage} secondaryLable={secondaryLable} secondaryAction={secondaryAction} secondaryType={secondaryType}>
            <div className="fileUploadSelection">
                <div className="fileUploadSelectionMain center">
                    <div className="fileUploadSelectionIcon">
                        <i className="fa-regular fa-images fileUploadSelectionIco"></i>
                    </div>
                    <div className="fileUploadSelectionLable">
                        <Text type="default" variant="fileupload-lable">select image</Text>
                    </div>
                </div>
                <input type="file" className="fileUploadSelectionInput" onChange={handleImageAsFile} />
                <div className="fileUploadSelectionPreview center">
                    <div className="fileUploadSelectionPreviewImage">
                        <img src={imagePreview} className="fileUploadSelectionPreviewImg" />
                    </div>
                </div>
            </div>
        </PopUp>
    )
}

export default FileUploadPopUp