import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { REQUEST_ERROR, HIGHLIGHTS_UPDATED } from '../../../../../../utils/messages';

const EditAboutUsHighlightsPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [lable1, setLable1] = useState('');
    const [stats1, setStats1] = useState('');
    const [lable2, setLable2] = useState('');
    const [stats2, setStats2] = useState('');
    const [lable3, setLable3] = useState('');
    const [stats3, setStats3] = useState('');
    const [lable4, setLable4] = useState('');
    const [stats4, setStats4] = useState('');

    const [isLable1Error, setIsLable1Error] = useState(false);
    const [isStats1Error, setIsStats1Error] = useState(false);
    const [isLable2Error, setIsLable2Error] = useState(false);
    const [isStats2Error, setIsStats2Error] = useState(false);
    const [isLable3Error, setIsLable3Error] = useState(false);
    const [isStats3Error, setIsStats3Error] = useState(false);
    const [isLable4Error, setIsLable4Error] = useState(false);
    const [isStats4Error, setIsStats4Error] = useState(false);

    const [lable1ErrorTxt, setLable1ErrorTxt] = useState("");
    const [stats1ErrorTxt, setStats1ErrorTxt] = useState("");
    const [lable2ErrorTxt, setLable2ErrorTxt] = useState("");
    const [stats2ErrorTxt, setStats2ErrorTxt] = useState("");
    const [lable3ErrorTxt, setLable3ErrorTxt] = useState("");
    const [stats3ErrorTxt, setStats3ErrorTxt] = useState("");
    const [lable4ErrorTxt, setLable4ErrorTxt] = useState("");
    const [stats4ErrorTxt, setStats4ErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const getAboutUsHighlightsHandler = async () => {
        try {
            const getAboutUsHighlightsReq = await axios.get(`${API_BASE_URL}about-us/highlights`);
            console.log(getAboutUsHighlightsReq);
            setLable1(getAboutUsHighlightsReq.data.lable1);
            setStats1(getAboutUsHighlightsReq.data.stats1);
            setLable2(getAboutUsHighlightsReq.data.lable2);
            setStats2(getAboutUsHighlightsReq.data.stats2);
            setLable3(getAboutUsHighlightsReq.data.lable3);
            setStats3(getAboutUsHighlightsReq.data.stats3);
            setLable4(getAboutUsHighlightsReq.data.lable4);
            setStats4(getAboutUsHighlightsReq.data.stats4);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getAboutUsHighlightsHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(lable1 == ''){
            setLable1ErrorTxt('Lable 1 is required');
            setIsLable1Error(true);
            return;
        }
        else{
            setLable1ErrorTxt('');
            setIsLable1Error(false);
        }

        if(stats1 == ''){
            setStats1ErrorTxt('Stats 1 is required');
            setIsStats1Error(true);
            return;
        }
        else{
            setStats1ErrorTxt('');
            setIsStats1Error(false);
        }

        if(lable2 == ''){
            setLable2ErrorTxt('Lable 2 is required');
            setIsLable2Error(true);
            return;
        }
        else{
            setLable2ErrorTxt('');
            setIsLable2Error(false);
        }

        if(stats2 == ''){
            setStats2ErrorTxt('Stats 2 is required');
            setIsStats2Error(true);
            return;
        }
        else{
            setStats2ErrorTxt('');
            setIsStats2Error(false);
        }

        if(lable3 == ''){
            setLable3ErrorTxt('Lable 3 is required');
            setIsLable3Error(true);
            return;
        }
        else{
            setLable3ErrorTxt('');
            setIsLable3Error(false);
        }

        if(stats3 == ''){
            setStats3ErrorTxt('Stats 3 is required');
            setIsStats3Error(true);
            return;
        }
        else{
            setStats3ErrorTxt('');
            setIsStats3Error(false);
        }

        if(lable4 == ''){
            setLable4ErrorTxt('Lable 4 is required');
            setIsLable4Error(true);
            return;
        }
        else{
            setLable4ErrorTxt('');
            setIsLable4Error(false);
        }

        if(stats4 == ''){
            setStats4ErrorTxt('Stats 4 is required');
            setIsStats4Error(true);
            return;
        }
        else{
            setStats4ErrorTxt('');
            setIsStats4Error(false);
        }

        const data = {
            lable1: lable1,
            stats1: stats1,
            lable2: lable2,
            stats2: stats2,
            lable3: lable3,
            stats3: stats3,
            lable4: lable4,
            stats4: stats4
        }

        try {
            const updateAboutUsHighlightsReq = await axios.patch(`${API_BASE_URL}about-us/highlights/edit`, data, config);
            notifySuccess(HIGHLIGHTS_UPDATED);
            navigate('/dashboard/pages/about-us/highlights');
            LOGGER &&  console.log(updateAboutUsHighlightsReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="Headings" description={routeText} action={() => navigate("/dashboard/pages/about-us/highlights")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="lable 1" isMandatory={true} isError={isLable1Error} error={lable1ErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={lable1} placeholder="Please Enter The Lable 1" onChange={(e) => setLable1(e.target.value)} />
                            </TextInput>
                            <TextInput lable="stats 1" isMandatory={true} isError={isStats1Error} error={stats1ErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={stats1} placeholder="Please Enter The Stats 1" onChange={(e) => setStats1(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="lable 2" isMandatory={true} isError={isLable2Error} error={lable2ErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={lable2} placeholder="Please Enter The Lable 2" onChange={(e) => setLable2(e.target.value)} />
                            </TextInput>
                            <TextInput lable="stats 2" isMandatory={true} isError={isStats2Error} error={stats2ErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={stats2} placeholder="Please Enter The Stats 2" onChange={(e) => setStats2(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="lable 3" isMandatory={true} isError={isLable3Error} error={lable3ErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={lable3} placeholder="Please Enter The Lable 3" onChange={(e) => setLable3(e.target.value)} />
                            </TextInput>
                            <TextInput lable="stats 3" isMandatory={true} isError={isStats3Error} error={stats3ErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={stats3} placeholder="Please Enter The Stats 3" onChange={(e) => setStats3(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="lable 4" isMandatory={true} isError={isLable4Error} error={lable4ErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={lable4} placeholder="Please Enter The Lable 4" onChange={(e) => setLable4(e.target.value)} />
                            </TextInput>
                            <TextInput lable="stats 4" isMandatory={true} isError={isStats4Error} error={stats4ErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={stats4} placeholder="Please Enter The Stats 4" onChange={(e) => setStats4(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditAboutUsHighlightsPage