import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DetailSection from '../../../../../components/dashboard/common/detailSection/DetailSection';
import DetailSectionContent from '../../../../../components/dashboard/common/detailSection/DetailSectionContent';
import DetailSectionRow from '../../../../../components/dashboard/common/detailSection/DetailSectionRow';

import NoDataContainer from '../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../../../../../config';

import { OPENING_DELETED, REQUEST_ERROR } from '../../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';

const BlogArticlesPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [articles, setArticles] = useState([]);
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getArticlesHandler = async () => {
        try {
            const getArticlesReq = await axios.get(`${API_BASE_URL}blog/article`);
            console.log(getArticlesReq.data);
            setArticles(getArticlesReq.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getArticlesHandler();
    }, []);

    const deleteThisArticleHandler = async (id) => {
        try {
            const deleteThisArticleReq = await axios.delete(`${API_BASE_URL}blog/article/${id}`, config);
            console.log(deleteThisArticleReq.data);
            getArticlesHandler();
            notifySuccess(OPENING_DELETED);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    return (
        <>
            <Section heading="Articles" description={routeText} action={() => navigate("/dashboard/pages/blogs")} actionLable="back" showAction={true}>
                <PlainContainer type="fullwidth">
                    <DetailSection lable="List of Articles" hasPrimaryAction={true} lablePrimaryAction={`create`} primaryAction={() => navigate("/dashboard/pages/blogs/articles/create")} hasSecondaryAction={false}>
                        <div style={{paddingTop: `20px`, paddingLeft: `20px`, paddingRight: `20px`}}>
                            {
                                articles.map(article => {
                                    return (
                                        <div style={{width: `100%`, float: `left`, marginBottom: `20px`}} key={article.id}>
                                            <DetailSection lable={article.title} hasPrimaryAction={true} lablePrimaryAction={`edit`} primaryAction={() => navigate(`/dashboard/pages/blogs/articles/edit/${article.id}`)} hasSecondaryAction={true} lableSecondaryAction={`delete`} secondaryAction={() => deleteThisArticleHandler(article.id)}>
                                                <DetailSectionContent hasPadding={true}>
                                                    <DetailSectionRow lable={`description`} value={article.description} isImage={false} isIcon={false} hasChildren={false} />
                                                </DetailSectionContent>
                                            </DetailSection>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </DetailSection>
                </PlainContainer>
            </Section>
        </>
    )
}

export default BlogArticlesPage