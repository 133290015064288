import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DetailSection from '../../../../../components/dashboard/common/detailSection/DetailSection';
import DetailSectionContent from '../../../../../components/dashboard/common/detailSection/DetailSectionContent';
import DetailSectionRow from '../../../../../components/dashboard/common/detailSection/DetailSectionRow';

import NoDataContainer from '../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../../../../../config';

import { PRODUCT_VARIANT_CREATION_FAILED, PRODUCT_VARIANT_DELETED, REQUEST_ERROR } from '../../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';

const AboutUsSeoPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [aboutSeo, setAboutSeo] = useState({});
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getAboutUsSeoHandler = async () => {
        try {
            const getAboutUsSeoReq = await axios.get(`${API_BASE_URL}about-us/seo`);
            setAboutSeo(getAboutUsSeoReq.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getAboutUsSeoHandler();
    }, []);

    return (
        <>
            <Section heading="SEO" description={routeText} action={() => navigate("/dashboard/pages/about-us")} actionLable="back" showAction={true}>
                <PlainContainer type="fullwidth">
                    <DetailSection lable="SEO" hasPrimaryAction={true} lablePrimaryAction={`edit`} primaryAction={() => navigate("/dashboard/pages/about-us/seo/edit")} hasSecondaryAction={false}>
                        <DetailSectionContent hasPadding={true}>
                            <DetailSectionRow lable={`title`} value={aboutSeo.title} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`description`} value={aboutSeo.description} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`keywords`} value={aboutSeo.keywords} isImage={false} isIcon={false} hasChildren={false} />
                        </DetailSectionContent>
                    </DetailSection>
                </PlainContainer>
            </Section>
        </>
    )
}

export default AboutUsSeoPage

