import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DetailSection from '../../../../../components/dashboard/common/detailSection/DetailSection';
import DetailSectionContent from '../../../../../components/dashboard/common/detailSection/DetailSectionContent';
import DetailSectionRow from '../../../../../components/dashboard/common/detailSection/DetailSectionRow';

import NoDataContainer from '../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../../../../../config';

import { REQUEST_ERROR } from '../../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';

const HomeAboutUsPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [homeAboutUs, setHomeAboutUs] = useState({});
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getHomeAboutUsHandler = async () => {
        try {
            const getHomeAboutUsReq = await axios.get(`${API_BASE_URL}home/about-us`);
            console.log(getHomeAboutUsReq.data);
            setHomeAboutUs(getHomeAboutUsReq.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getHomeAboutUsHandler();
    }, []);

    return (
        <>
            <Section heading="about us" description={routeText} action={() => navigate("/dashboard/pages/home")} actionLable="back" showAction={true}>
                <PlainContainer type="fullwidth">
                    <DetailSection lable="about us" hasPrimaryAction={true} lablePrimaryAction={`edit`} primaryAction={() => navigate("/dashboard/pages/home/about-us/edit")} hasSecondaryAction={false}>
                        <DetailSectionContent hasPadding={true}>
                            <DetailSectionRow lable={`section heading`} value={homeAboutUs.section_heading} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`heading bold`} value={homeAboutUs.heading_bold} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`heading regular`} value={homeAboutUs.heading_regular} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`description`} value={homeAboutUs.description} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`mission statement`} value={homeAboutUs.mission_statement} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`quote`} value={homeAboutUs.quote} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`auther name`} value={homeAboutUs.quote_author} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`auther designation`} value={homeAboutUs.author_designation} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`featured image`} value={homeAboutUs.featured_image?.url} isImage={true} isIcon={false} hasChildren={false} />
                        </DetailSectionContent>
                    </DetailSection>
                </PlainContainer>
            </Section>
        </>
    )
}

export default HomeAboutUsPage