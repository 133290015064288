import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DetailSection from '../../../../../components/dashboard/common/detailSection/DetailSection';
import DetailSectionContent from '../../../../../components/dashboard/common/detailSection/DetailSectionContent';
import DetailSectionRow from '../../../../../components/dashboard/common/detailSection/DetailSectionRow';

import NoDataContainer from '../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../../../../../config';

import { REQUEST_ERROR } from '../../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';
import moment from 'moment';

const DealershipRequestDetailPage = () => {
    const admin = useSelector((state) => state.admin.value);

    let navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    const [request, setRequest] = useState({});
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getThisDealershipRequestHandler = async () => {
        try {
            const getDealershipReq = await axios.get(`${API_BASE_URL}dealership/request/${params.id}`, config);
            setRequest(getDealershipReq.data.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getThisDealershipRequestHandler();
    }, []);

    return (
        <>
            <Section heading="dealership request" description={routeText} action={() => navigate(-1)} actionLable="back" showAction={true}>
                <PlainContainer type="fullwidth">
                    <DetailSection lable="Request Details" hasPrimaryAction={false}>
                        <DetailSectionContent hasPadding={true}>
                            <DetailSectionRow lable={`name`} value={request?.name} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`phone number`} value={request?.phone_number} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`email ID`} value={request?.email_id} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`company name`} value={request?.company_name} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`nature of business`} value={request?.nature_of_business} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`years in business`} value={request?.years_in_business} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`state`} value={request?.state} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`city`} value={request?.city} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`investment range`} value={request?.investment_range} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`hear about us`} value={request?.hear_about_us} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`received on`} value={moment(request?.date_time).format('DD/MM/YYYY')} isImage={false} isIcon={false} hasChildren={false} />
                        </DetailSectionContent>
                    </DetailSection>
                </PlainContainer>
            </Section>
        </>
    )
}

export default DealershipRequestDetailPage