import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_BASE_URL, LOGGER } from '../../../config';
import { REQUEST_ERROR } from '../../../utils/messages';
import { notifyError } from '../../../utils/toastify';
import PlainContainer from '../../general/containers/PlainContainer';
import StatSingle from './StatSingle';

const Stats = ({auth}) => {

    const [appointmentRequests, setAppointmentRequests] = useState(0);
    const [callbackRequests, setCallbackRequests] = useState(0);
    const [contactRequests, setContactRequests] = useState(0);
    const [dealershipRequests, setDealershipRequests] = useState(0);

    const getRequestStats = async () => {
        try{
            const getRequestsStatsReq = await axios.get(`${API_BASE_URL}stats`, auth);
            // console.log(getRequestsStatsReq);
            if (getRequestsStatsReq.status === 200) {
                setAppointmentRequests(parseInt(getRequestsStatsReq.data.data.appointment_requests_count));
                setCallbackRequests(parseInt(getRequestsStatsReq.data.data.callback_requests_count));
                setContactRequests(parseFloat(getRequestsStatsReq.data.data.contact_requests_count));
                setDealershipRequests(parseInt(getRequestsStatsReq.data.data.dealership_requests_count));
            }
        }
        catch(error) {
            notifyError(REQUEST_ERROR);
            // LOGGER &&  console.log(error);
        }
    }

    useEffect(() => {
        getRequestStats();
    }, []);


    return (
        <PlainContainer type="fullwidth" styles={{display: 'flex', flexDirection: 'row'}}>
            <StatSingle color="#c02329" showIcon={false} icon="" lable="appointment requests" value={appointmentRequests} />
            <StatSingle color="#278855" showIcon={false} icon="" lable="contact enquiries" value={contactRequests} />
            <StatSingle color="#0a1f3e" showIcon={false} icon="" lable="callback requests" value={callbackRequests} />
            <StatSingle color="#9c9a2d" showIcon={false} icon="" lable="dealership requests" value={dealershipRequests} />
        </PlainContainer>
    )
}

export default Stats