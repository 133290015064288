import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Table from '../../../../components/dashboard/common/table/Table';
import Heading from '../../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../../components/dashboard/common/table/HeadingItem';
import TableRow from '../../../../components/dashboard/common/table/TableRow';
import RowItem from '../../../../components/dashboard/common/table/RowItem';
import RowIcon from '../../../../components/dashboard/common/table/RowIcon';
import TableButton from '../../../../components/dashboard/common/table/TableButton';

import NoDataContainer from '../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../components/general/containers/SplitContainer';
import Section from '../../../../components/general/containers/Section';
import Pagination from '../../../../components/general/Pagination';

import { API_BASE_URL, LOGGER } from '../../../../config';
import { notifyError, notifySuccess } from '../../../../utils/toastify';
import moment from 'moment';

const DealershipRequestListPage = () => {

    const admin = useSelector((state) => state.admin.value);

    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    
    const [requests, setRequests] = useState([]);
    const [totalRequestsCount, setTotalRequestsCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getDealershipRequestsHandler = async (page) => {
        try {
            const getDealershipsReq = await axios.get(`${API_BASE_URL}dealership/request?page=${page}&size=10`, config);
            console.log(getDealershipsReq.data.data);
            if(getDealershipsReq.data.data.items.length != 0){
                setRequests(getDealershipsReq.data.data);
                setTotalPagesCount(getDealershipsReq.data.data.total_pages);
                setTotalRequestsCount(getDealershipsReq.data.data.total_items);
            }
            else{
                setRequests({});
            }
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });

        setRouteText(removeLastChars(tempRoute, 3));
        
        getDealershipRequestsHandler(currentPage);
    }, [currentPage]);

    return(
        <>
            <Section heading="Dealership Requests" description={routeText} showAction={false}>
                {
                    requests?.items?.length > 0
                    ?
                    <>
                        <Table>
							<Heading>
								<HeadingItem size="1" isIcon={false} icon="" lable={`SL NO`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`name`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`phone no.`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`investment`} />
								<HeadingItem size="2" isIcon={false} icon="" lable={`received on`} />
								<HeadingItem size="1" isIcon={false} icon="" lable={``} />
							</Heading>

                            <PlainContainer styles={{width: '100%'}}>
								{
									requests.items.map((req, i) => {
										return (
											<TableRow key={i}>
												<RowItem size="1" lable={i+1} isCenter={true} hasChildren={false}></RowItem>
												<RowItem size="2" lable={req.name} isCenter={false} hasChildren={false}></RowItem>
												<RowItem size="2" lable={req.phone_number} isCenter={false} hasChildren={false}></RowItem>
												<RowItem size="2" lable={req.investment_range} isCenter={false} hasChildren={false}></RowItem>
												{/* <RowItem size="2" lable={moment(req.date_time).format('MMMM Do YYYY, h:mm a')} isCenter={true} hasChildren={false}></RowItem> */}
												<RowItem size="2" lable={moment(req.date_time).format('DD/MM/YYYY')} isCenter={true} hasChildren={false}></RowItem>
                                                <RowItem size="1" lable="2" isCenter={false} hasChildren={true}>
													<RowIcon name="fa-solid fa-eye" action={() => navigate(`details/${req.id}`)} styles={{cursor: 'pointer'}} />
												</RowItem>
											</TableRow>
										)
									})
								}
							</PlainContainer>

                        </Table>

                        {
                            requests.total_pages > 1
                            ?
                            <PlainContainer type={"fullwidth"} styles={{ padding: "0px 25px" }}>
                                <Pagination totalPages={totalPagesCount} setCurrentPage={setCurrentPage}/>
                            </PlainContainer>
                            :
                            <></>
                        }
                    </>
                    :
                    <>No Requests Found</>
                }
            </Section>
        </>
    )

}

export default DealershipRequestListPage