import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import FileInput from '../../../../../../components/general/forms/FileInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { REQUEST_ERROR, PHONE_NUMBER_ADDED, CLIENT_CREATED } from '../../../../../../utils/messages';

const AddClientPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [name, setName] = useState('');
    const [logo, setLogo] = useState('');
    const [logoID, setLogoID] = useState('');

    const [isNameError, setIsNameError] = useState(false);
    const [isLogoError, setIsLogoError] = useState(false);

    const [nameErrorTxt, setNameErrorTxt] = useState("");
    const [logoErrorTxt, setLogoErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(name == ''){
            setNameErrorTxt('Name is required');
            setIsNameError(true);
            return;
        }
        else{
            setNameErrorTxt('');
            setIsNameError(false);
        }

        if(logoID == ''){
            setLogoErrorTxt('Logo is required');
            setIsLogoError(true);
            return;
        }
        else{
            setLogoErrorTxt('');
            setIsLogoError(false);
        }

        const data = {
            name: name,
            logo: logoID
        }

        try {
            const updateClientReq = await axios.post(`${API_BASE_URL}client/list`, data, config);
            notifySuccess(CLIENT_CREATED);
            navigate('/dashboard/pages/clients/clients');
            LOGGER &&  console.log(updateClientReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="Clients" description={routeText} action={() => navigate("/dashboard/pages/clients/clients")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <TextInput lable="name" isMandatory={true} isError={isNameError} error={nameErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={name} placeholder="Please Enter The Name" onChange={(e) => setName(e.target.value)} />
                        </TextInput>
                        <FileInput lable="logo" isMandatory={true} value={logoID} preview={logo} onchange={(data) => {setLogoID(data); LOGGER &&  console.log(data)}} isError={isLogoError} error={logoErrorTxt} reset={resetAttachment} />
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default AddClientPage