import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PlainContainer from '../../../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../../../components/general/containers/Section';
import Form from '../../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../../components/general/forms/TextInput';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../../utils/toastify';
import { REQUEST_ERROR, CATEGORY_ADDED } from '../../../../../../../utils/messages';

const AddProductCategoryHighlightPage = () => {
    let navigate = useNavigate();
    const location = useLocation();
    let { category_id } = useParams();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [description, setDescription] = useState('');

    const [isDescriptionError, setIsDescriptionError] = useState(false);

    const [descriptionErrorTxt, setDescriptionErrorTxt] = useState("");

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(description == ''){
            setDescriptionErrorTxt('Title is required');
            setIsDescriptionError(true);
            return;
        }
        else{
            setDescriptionErrorTxt('');
            setIsDescriptionError(false);
        }

        const data = {
            description: description
        }

        try {
            const createProductCategoryReq = await axios.post(`${API_BASE_URL}product/category/highlight/${category_id}`, data, config);
            notifySuccess(CATEGORY_ADDED);
            navigate(`/dashboard/pages/products/categories/details/${category_id}`);
            LOGGER &&  console.log(createProductCategoryReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="Highlights" description={routeText} action={() => navigate(`/dashboard/pages/products/categories/details/${category_id}`)} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <TextInput lable="title" isMandatory={true} isError={isDescriptionError} error={descriptionErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={description} placeholder="Please Enter The Title" onChange={(e) => setDescription(e.target.value)} />
                        </TextInput>
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default AddProductCategoryHighlightPage