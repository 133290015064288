import React, { useState, useEffect, useRef, forwardRef, useMemo} from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import Button from '../../../../../../components/general/forms/Button';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { REQUEST_ERROR, OPENING_CREATED } from '../../../../../../utils/messages';
import SelectInput from '../../../../../../components/general/forms/SelectInput';
import FileInput from '../../../../../../components/general/forms/FileInput';

const EditBlogArticlePage = () => {
    let navigate = useNavigate();
    const location = useLocation();
    let { article_id } = useParams();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const quillRef = useRef();

    const imageHandler = async (e) => {
        const editor = quillRef.current.getEditor();
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input.files[0];

            // file type is only image.
            if (/^image\//.test(file.type)) {
                const data = new FormData();
                data.append("image_url", file);
        
                try {
                    const saveEditorImageReq = await axios.post(`${API_BASE_URL}media`, data, config);
                    notifySuccess(OPENING_CREATED);
                    LOGGER &&  console.log(saveEditorImageReq);
                    const url = `${API_BASE_URL}${saveEditorImageReq.data.url}`;
                    editor.insertEmbed(editor.getSelection(), "image", url);
                }
                catch (error) {
                    notifyError(REQUEST_ERROR);
                    LOGGER &&  console.log(error);
                }
            } else {
                console.warn("You could only upload images.");
            }
        };
    };

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [
                    { 'header': [1, 2, 3, 4, 5, 6, false] }
                ],
                ['bold', 'italic', 'underline', "strike"],
                [{ 'align': [] }],
                [
                    { 'list': 'ordered' }, { 'list': 'bullet' },
                    { 'indent': '-1' }, { 'indent': '+1' }
                ],
                ['image', "link"],
                [
                    { 'color': 
                        ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] 
                    }
                ]
            ],
            handlers: {
                image: imageHandler
            }
        },
    }), []);

    const [article, setArticle] = useState({});

    const [seoTitle, setSeoTitle] = useState('');
    const [seoSlug, setSeoSlug] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [seoKeywords, setSeoKeywords] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState(0);
    const [categories, setCategories] = useState([]);
    const [thumbnail, setThumbnail] = useState('');
    const [thumbnailID, setThumbnailID] = useState('');
    const [cover, setCover] = useState('');
    const [coverID, setCoverID] = useState('');
    const [editorData, setEditorData] = useState('');

    const [isSeoTitleError, setIsSeoTitleError] = useState(false);
    const [isSeoSlugError, setIsSeoSlugError] = useState(false);
    const [isSeoDescriptionError, setIsSeoDescriptionError] = useState(false);
    const [isSeoKeywordsError, setIsSeoKeywordsError] = useState(false);
    const [isTitleError, setIsTitleError] = useState(false);
    const [isDescriptionError, setIsDescriptionError] = useState(false);
    const [isCategoryError, setIsCategoryError] = useState(false);
    const [isThumbnailError, setIsThumbnailError] = useState(false);
    const [isCoverError, setIsCoverError] = useState(false);
    const [isEditorDataError, setIsEditorDataError] = useState(false);

    const [seoTitleErrorTxt, setSeoTitleErrorTxt] = useState("");
    const [seoSlugErrorTxt, setSeoSlugErrorTxt] = useState("");
    const [seoDescriptionErrorTxt, setSeoDescriptionErrorTxt] = useState("");
    const [seoKeywordsErrorTxt, setSeoKeywordsErrorTxt] = useState("");
    const [titleErrorTxt, setTitleErrorTxt] = useState("");
    const [descriptionErrorTxt, setDescriptionErrorTxt] = useState("");
    const [categoryErrorTxt, setCategoryErrorTxt] = useState("");
    const [thumbnailErrorTxt, setThumbnailErrorTxt] = useState("");
    const [coverErrorTxt, setCoverErrorTxt] = useState("");
    const [editorDataErrorTxt, setEditorDataErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const [showThumbnailPreview, setShowThumbnailPreview] = useState(false);
    const [showCoverPreview, setShowCoverPreview] = useState(false);

    const getBlogArticleHandler = async () => {
        try {
            const getBlogArticleReq = await axios.get(`${API_BASE_URL}blog/article/${article_id}`);
            console.log(getBlogArticleReq.data);
            setArticle(getBlogArticleReq.data);
            setSeoTitle(getBlogArticleReq.data.seo.title);
            setSeoDescription(getBlogArticleReq.data.seo.description);
            setSeoSlug(getBlogArticleReq.data.seo.slug);
            setSeoKeywords(getBlogArticleReq.data.seo.keywords);
            setTitle(getBlogArticleReq.data.title);
            setDescription(getBlogArticleReq.data.description);
            setEditorData(getBlogArticleReq.data.rich_editor_data);
            setCategory(getBlogArticleReq.data.category.id);
            setThumbnail(getBlogArticleReq.data.blog_thumbnail.url);
            setThumbnailID(getBlogArticleReq.data.blog_thumbnail.id);
            setShowThumbnailPreview(true);
            setCover(getBlogArticleReq.data.blog_cover.url);
            setCoverID(getBlogArticleReq.data.blog_cover.id);
            setShowCoverPreview(true);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getBlogArticleHandler();
    }, []);

    const getBlogCategoriesHandler = async () => {
        try {
            const getBlogCategoriesReq = await axios.get(`${API_BASE_URL}blog/category`);
            console.log(getBlogCategoriesReq.data);
            setCategories(getBlogCategoriesReq.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getBlogCategoriesHandler();
    }, []);

    const slugChanged = async (data) => {
        setSeoSlug(data);
        try{
            const slugStatusReq = await axios.get(`${API_BASE_URL}blog/article/check-slug?slug=${data}`, config);
            if(slugStatusReq.status === 200){
                setSeoSlugErrorTxt('');
                setIsSeoSlugError(false);
            }
        }
        catch(error){
            if(error.response.status === 403){
                setSeoSlugErrorTxt('SEO URL Unavailable');
                setIsSeoSlugError(true);
            }
            else{
                setSeoSlugErrorTxt("Something Went Wrong. Try Again.");
                setIsSeoSlugError(true);
            }
        }
    }

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(seoTitle == ''){
            setSeoTitleErrorTxt('SEO Title is required');
            setIsSeoTitleError(true);
            return;
        }
        else{
            setSeoTitleErrorTxt('');
            setIsSeoTitleError(false);
        }

        if(seoSlug == ''){
            setSeoSlugErrorTxt('Slug is required');
            setIsSeoSlugError(true);
            return;
        }
        else{
            setSeoSlugErrorTxt('');
            setIsSeoSlugError(false);
        }

        if(title == ''){
            setTitleErrorTxt('Title is required');
            setIsTitleError(true);
            return;
        }
        else{
            setTitleErrorTxt('');
            setIsTitleError(false);
        }

        if(description == ''){
            setDescriptionErrorTxt('Description is required');
            setIsSeoDescriptionError(true);
            return;
        }
        else{
            setDescriptionErrorTxt('');
            setIsSeoDescriptionError(false);
        }

        if(category == 0){
            setCategoryErrorTxt('Description is required');
            setIsCategoryError(true);
            return;
        }
        else{
            setCategoryErrorTxt('');
            setIsCategoryError(false);
        }

        if(thumbnailID == ''){
            setThumbnailErrorTxt('Thumbnail is required');
            setIsThumbnailError(true);
            return;
        }
        else{
            setThumbnailErrorTxt('');
            setIsThumbnailError(false);
        }

        if(coverID == ''){
            setCoverErrorTxt('Cover is required');
            setIsCoverError(true);
            return;
        }
        else{
            setCoverErrorTxt('');
            setIsCoverError(false);
        }

        if(editorData == ''){
            setEditorDataErrorTxt('Article data is required');
            setIsEditorDataError(true);
            return;
        }
        else{
            setEditorDataErrorTxt('');
            setIsEditorDataError(false);
        }

        const data = {
            seo_title: seoTitle,
            slug: seoSlug,
            seo_description: seoDescription,
            seo_keywords: seoKeywords,
            title: title,
            description: description,
            category: category,
            blog_thumbnail: thumbnailID,
            blog_cover: coverID,
            rich_editor_data: editorData
        }

        try {
            const createBlogArticleReq = await axios.patch(`${API_BASE_URL}blog/article/${article_id}`, data, config);
            notifySuccess(OPENING_CREATED);
            navigate('/dashboard/pages/blogs/articles');
            LOGGER &&  console.log(createBlogArticleReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="Articles" description={routeText} action={() => navigate("/dashboard/pages/blogs/articles")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="SEO Title" isMandatory={true} isError={isSeoTitleError} error={seoTitleErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={seoTitle} placeholder="Please Enter The SEO Title" onChange={(e) => setSeoTitle(e.target.value)} />
                            </TextInput>
                            <TextInput lable="Page URL" isMandatory={true} isError={isSeoSlugError} error={seoSlugErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={seoSlug} placeholder="Please Enter The Page URL" onChange={(e) => slugChanged(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <TextArea lable="SEO description" rows={3} isMandatory={false} placeholder="Please Enter The SEO Description" value={seoDescription} onchange={(data) => setSeoDescription(data)} isError={isSeoDescriptionError} error={seoDescriptionErrorTxt} />
                        <TextArea lable="SEO keywords" rows={3} isMandatory={false} placeholder="Please Enter The SEO Keywords" value={seoKeywords} onchange={(data) => setSeoKeywords(data)} isError={isSeoKeywordsError} error={seoKeywordsErrorTxt} />
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="title" isMandatory={true} isError={isTitleError} error={titleErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={title} placeholder="Please Enter The Title" onChange={(e) => setTitle(e.target.value)} />
                            </TextInput>
                            <SelectInput lable="blog category" capitalize={true} isMandatory={false} placeholder="Please Select The Category" value={category} onchange={(data) => setCategory(data)} isError={isCategoryError} error={categoryErrorTxt} options={categories} />
                        </SplitContainer>
                        <TextArea lable="Brief description" rows={3} isMandatory={false} placeholder="Please Enter The Brief Description" value={description} onchange={(data) => setDescription(data)} isError={isDescriptionError} error={descriptionErrorTxt} />
                        <TextInput lable="Article Data" isMandatory={true} isError={isEditorDataError} error={editorDataErrorTxt}>
                            <ReactQuill modules={modules} ref={quillRef} style={{height: '500px', paddingBottom: '45px'}} theme="snow" value={editorData} onChange={setEditorData} />
                        </TextInput>
                        <FileInput lable="thumbnail" isMandatory={true} value={thumbnailID} preview={thumbnail} showPreview={showThumbnailPreview} onchange={(data) => {setThumbnailID(data); LOGGER &&  console.log(data)}} isError={isThumbnailError} error={thumbnailErrorTxt} reset={resetAttachment} />
                        <FileInput lable="cover" isMandatory={true} value={coverID} preview={cover} showPreview={showCoverPreview} onchange={(data) => {setCoverID(data); LOGGER &&  console.log(data)}} isError={isCoverError} error={coverErrorTxt} reset={resetAttachment} />
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditBlogArticlePage