import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { ACTION_BANNER_UPDATED, REQUEST_ERROR } from '../../../../../../utils/messages';

const EditHeaderActionBannerPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [title, setTitle] = useState('');
    const [buttonLable, setButtonLable] = useState('');
    const [buttonURL, setButtonURL] = useState('');

    const [isTitleError, setIsTitleError] = useState(false);
    const [isButtonLableError, setIsButtonLableError] = useState(false);
    const [isButtonURLError, setIsButtonURLError] = useState(false);

    const [titleErrorTxt, setTitleErrorTxt] = useState("");
    const [buttonLableErrorTxt, setButtonLableErrorTxt] = useState("");
    const [buttonURLErrorTxt, setButtonURLErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const getHeaderActionBannerHandler = async () => {
        try {
            const getHeaderActionBannerReq = await axios.get(`${API_BASE_URL}header/action-banner`);
            console.log(getHeaderActionBannerReq);
            setTitle(getHeaderActionBannerReq.data.title);
            setButtonLable(getHeaderActionBannerReq.data.button_lable);
            setButtonURL(getHeaderActionBannerReq.data.button_url);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getHeaderActionBannerHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(title == ''){
            setTitleErrorTxt('Title is required');
            setIsTitleError(true);
            return;
        }
        else{
            setTitleErrorTxt('');
            setIsTitleError(false);
        }

        if(buttonLable == ''){
            setButtonLableErrorTxt('Button lable is required');
            setIsButtonLableError(true);
            return;
        }
        else{
            setButtonLableErrorTxt('');
            setIsButtonLableError(false);
        }

        if(buttonURL == ''){
            setButtonURLErrorTxt('Button URL is required');
            setIsButtonURLError(true);
            return;
        }
        else{
            setButtonURLErrorTxt('');
            setIsButtonURLError(false);
        }

        const data = {
            title: title,
            button_lable: buttonLable,
            button_url: buttonURL
        }

        try {
            const updateHeaderActionBannerReq = await axios.patch(`${API_BASE_URL}header/action-banner/edit`, data, config);
            notifySuccess(ACTION_BANNER_UPDATED);
            navigate('/dashboard/general/header/action-banner');
            LOGGER &&  console.log(updateHeaderActionBannerReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="action banner" description={routeText} action={() => navigate("/dashboard/general/header/action-banner")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <TextInput lable="title" isMandatory={true} isError={isTitleError} error={titleErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={title} placeholder="Please Enter The Title" onChange={(e) => setTitle(e.target.value)} />
                        </TextInput>
                        <SplitContainer type="fullwidth">
                            <TextInput lable="button lable" isMandatory={true} isError={isButtonLableError} error={buttonLableErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={buttonLable} placeholder="Please Enter The Button Lable" onChange={(e) => setButtonLable(e.target.value)} />
                            </TextInput>
                            <TextInput lable="button URL" isMandatory={true} isError={isButtonURLError} error={buttonURLErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={buttonURL} placeholder="Please Enter The Button URL" onChange={(e) => setButtonURL(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditHeaderActionBannerPage