import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Heading from '../../../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../../../components/dashboard/common/table/HeadingItem';
import RowImage from '../../../../../components/dashboard/common/table/RowImage';
import RowItem from '../../../../../components/dashboard/common/table/RowItem';
import Table from '../../../../../components/dashboard/common/table/Table';
import TableButton from '../../../../../components/dashboard/common/table/TableButton';
import TableRow from '../../../../../components/dashboard/common/table/TableRow';

import NoDataContainer from '../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../../../../../config';

import { CLIENT_DELETED, REQUEST_ERROR } from '../../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';

const ClientsListPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [clients, setClients] = useState([]);
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getClientsHandler = async () => {
        try {
            const getClientsReq = await axios.get(`${API_BASE_URL}client/list`);
            console.log(getClientsReq.data);
            setClients(getClientsReq.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getClientsHandler();
    }, []);

    const deleteItem = async (id) => {
        try {
            const deleteClientReq = await axios.delete(`${API_BASE_URL}client/list/${id}`, config);
            notifySuccess(CLIENT_DELETED);
            getClientsHandler();
            LOGGER &&  console.log(deleteClientReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    return (
        <Section heading="clients" description={routeText} action={() => navigate("create")} actionLable="add new" showAction={true}>
            <Table>
                <Heading>
                    <HeadingItem size="1" isIcon={false} icon="" lable={`sl no`} />
                    <HeadingItem size="4" isIcon={false} icon="" lable={`name`} />
                    <HeadingItem size="2" isIcon={false} icon="" lable={`logo`} />
                    <HeadingItem size="3" isIcon={false} icon="" lable={`action`} />
                </Heading>
                <PlainContainer styles={{width: '100%'}}>
                    {
                        clients.map(client => {
                            return (
                                <TableRow key={client.id}>
                                    <RowItem size="1" lable={client.counter} isCenter={true} hasChildren={false}></RowItem>
                                    <RowItem size="4" lable={client.name} isCenter={true} hasChildren={false}></RowItem>
                                    <RowItem size="2" isCenter={false} hasChildren={true}>
                                        <RowImage src={client.logo} />
                                    </RowItem>
                                    <RowItem size="3" lable="3" isCenter={false} hasChildren={true}>
                                        <TableButton styles={{width: 'calc(50% - 5px)', marginRight: '10px'}} action={() => navigate(`edit/${client.id}`)} type="primary">edit</TableButton>
                                        <TableButton styles={{width: 'calc(50% - 5px)'}} action={() => deleteItem(client.id)} type="danger">delete</TableButton>
                                    </RowItem>
                                </TableRow>
                            )
                        })
                    }
                </PlainContainer>
            </Table>
        </Section>
    )
}

export default ClientsListPage