import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { MAKE_APPOINTMENT_UPDATED, REQUEST_ERROR } from '../../../../../../utils/messages';

const EditHomeMakeAppointmentPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [sectionHeading, setSectionHeading] = useState('');
    const [headingBold, setHeadingBold] = useState('');
    const [headingRegular, setHeadingRegular] = useState('');
    const [formHeading, setFormHeading] = useState('');
    const [formDescription, setFormDescription] = useState('');

    const [isSectionHeadingError, setIsSectionHeadingError] = useState(false);
    const [isHeadingBoldError, setIsHeadingBoldError] = useState(false);
    const [isHeadingRegularError, setIsHeadingRegularError] = useState(false);
    const [isFormHeadingError, setIsFormHeadingError] = useState(false);
    const [isFormDescriptionError, setIsFormDescriptionError] = useState(false);

    const [sectionHeadingErrorTxt, setSectionHeadingErrorTxt] = useState("");
    const [headingBoldErrorTxt, setHeadingBoldErrorTxt] = useState("");
    const [headingRegularErrorTxt, setHeadingRegularErrorTxt] = useState("");
    const [formHeadingErrorTxt, setFormHeadingErrorTxt] = useState("");
    const [formDescriptionErrorTxt, setFormDescriptionErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const getHomeMakeAppointmentHandler = async () => {
        try {
            const getHomeMakeAppointmentReq = await axios.get(`${API_BASE_URL}home/make-appointment`);
            console.log(getHomeMakeAppointmentReq);
            setSectionHeading(getHomeMakeAppointmentReq.data.section_heading);
            setHeadingBold(getHomeMakeAppointmentReq.data.heading_bold);
            setHeadingRegular(getHomeMakeAppointmentReq.data.heading_regular);
            setFormHeading(getHomeMakeAppointmentReq.data.form_heading);
            setFormDescription(getHomeMakeAppointmentReq.data.form_description);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getHomeMakeAppointmentHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(sectionHeading == ''){
            setSectionHeadingErrorTxt('Heading is required');
            setIsSectionHeadingError(true);
            return;
        }
        else{
            setSectionHeadingErrorTxt('');
            setIsSectionHeadingError(false);
        }

        if(headingBold == ''){
            setHeadingBoldErrorTxt('Heading bold is required');
            setIsHeadingBoldError(true);
            return;
        }
        else{
            setHeadingBoldErrorTxt('');
            setIsHeadingBoldError(false);
        }

        if(headingRegular == ''){
            setHeadingRegularErrorTxt('Heading regular is required');
            setIsHeadingRegularError(true);
            return;
        }
        else{
            setHeadingRegularErrorTxt('');
            setIsHeadingRegularError(false);
        }

        if(formHeading == ''){
            setFormHeadingErrorTxt('Form heading is required');
            setIsFormHeadingError(true);
            return;
        }
        else{
            setFormHeadingErrorTxt('');
            setIsFormHeadingError(false);
        }

        const data = {
            section_heading: sectionHeading,
            heading_bold: headingBold,
            heading_regular: headingRegular,
            form_heading: formHeading,
            form_description: formDescription
        }

        try {
            const updateHomeMakeAppointmentReq = await axios.patch(`${API_BASE_URL}home/make-appointment/edit`, data, config);
            notifySuccess(MAKE_APPOINTMENT_UPDATED);
            navigate('/dashboard/pages/home/make-appointment');
            LOGGER &&  console.log(updateHomeMakeAppointmentReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="make appointment" description={routeText} action={() => navigate("/dashboard/pages/home/make-appointment")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <TextInput lable="section heading" isMandatory={true} isError={isSectionHeadingError} error={sectionHeadingErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={sectionHeading} placeholder="Please Enter The Section Heading" onChange={(e) => setSectionHeading(e.target.value)} />
                        </TextInput>
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="heading bold" isMandatory={true} isError={isHeadingBoldError} error={headingBoldErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={headingBold} placeholder="Please Enter The Heading Bold" onChange={(e) => setHeadingBold(e.target.value)} />
                            </TextInput>
                            <TextInput lable="heading regular" isMandatory={true} isError={isHeadingRegularError} error={headingRegularErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={headingRegular} placeholder="Please Enter The Heading Regular" onChange={(e) => setHeadingRegular(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <TextInput lable="form heading" isMandatory={true} isError={isFormHeadingError} error={formHeadingErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={formHeading} placeholder="Please Enter The Form Heading" onChange={(e) => setFormHeading(e.target.value)} />
                        </TextInput>
                        <TextArea lable="form description" rows={3} isMandatory={false} placeholder="Please Enter The Form Description" value={formDescription} onchange={(data) => setFormDescription(data)} isError={isFormDescriptionError} error={formDescriptionErrorTxt} />
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditHomeMakeAppointmentPage