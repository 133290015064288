import React, { useEffect, useState } from 'react';
import NavItem from './navigation/NavItem';
import NavSection from './navigation/NavSection';
import { BRAND_NAME, ROLES } from '../../../config';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const routeList = {
    home: [{name:"home", goTo:"/dashboard", icon:"fas fa-home"}],
    general: [
        {name:"header", goTo:"/dashboard/general/header", icon:"fa-solid fa-arrow-up"},
        {name:"footer", goTo:"/dashboard/general/footer", icon:"fa-solid fa-arrow-down"},
    ],
    pages: [
        {name:"home", goTo:"/dashboard/pages/home", icon:"fa-solid fa-house-chimney"},
        {name:"about us", goTo:"/dashboard/pages/about-us", icon:"fa-solid fa-address-card"},
        {name:"products", goTo:"/dashboard/pages/products", icon:"fa-solid fa-cubes"},
        {name:"gallery", goTo:"/dashboard/pages/gallery", icon:"fa-solid fa-images"},
        {name:"clients", goTo:"/dashboard/pages/clients", icon:"fa-solid fa-handshake"},
        {name:"contact us", goTo:"/dashboard/pages/contact-us", icon:"fa-solid fa-address-book"},
        {name:"careers", goTo:"/dashboard/pages/careers", icon:"fa-solid fa-user-tag"},
        {name:"dealership form", goTo:"/dashboard/pages/dealership-form", icon:"fa-solid fa-business-time"},
        {name:"blog", goTo:"/dashboard/pages/blogs", icon:"fa-solid fa-newspaper"},
    ],
    leads: [
        {name:"appointment requests", goTo:"/dashboard/leads/appointment-requests", icon:"fa-solid fa-house-chimney"},
        {name:"contact enquiries", goTo:"/dashboard/leads/contact-enquiries", icon:"fa-solid fa-address-card"},
        {name:"callback requests", goTo:"/dashboard/leads/callback-requests", icon:"fa-solid fa-cubes"},
        {name:"dealership requests", goTo:"/dashboard/leads/dealership-requests", icon:"fa-solid fa-cubes"}
    ]
    // users: [
    //     {name:"super admins", goTo:"/dashboard/users/super-admins", icon:"fa-solid fa-user-secret"},
    //     {name:"admins", goTo:"/dashboard/users/admins", icon:"fa-solid fa-user-shield"}
    // ]
}

const Navbar = ({ showNav }) => {
    const location = useLocation();
    const admin = useSelector((state) => state.admin.value);
    
    const isAdmin = ROLES.ADMIN === admin.role;
    const isSuperAdmin = ROLES.SUPER_ADMIN === admin.role;
    const isStoreAdmin = ROLES.STORE_ADMIN === admin.role;

    const [lableText, setLableText] = useState(BRAND_NAME);

    useEffect(() => {
        if(showNav === true){
            setLableText(BRAND_NAME);
        }
        else{
            setLableText(BRAND_NAME.charAt(0));
        }
    }, [showNav]);

    return (
        <div className={showNav ? "dashboardNav active" : "dashboardNav"}>
            <div className="dashboardNavHeader">
                <div className="dashboardNavHeaderText">
                    <p className="dashboardNavHeaderTxt">{lableText}</p>
                </div>
            </div>
            <div className="dashboardNavMain">
                <div className="dashboardNavInner">
                    <NavSection lable="main" showNav={showNav}>
                        {
                            routeList.home.map(({name, goTo, icon})=>(
                                <NavItem key={goTo} goTo={goTo} name={name} icon={icon} showNav={showNav} active={location.pathname === goTo} />
                            ))
                        }
                    </NavSection>

                    {   (isAdmin || isSuperAdmin) &&
                        <NavSection lable="general" showNav={showNav}>
                        {
                            routeList.general.map(({name, goTo, icon})=>(
                                <NavItem key={goTo} goTo={goTo} name={name} icon={icon} showNav={showNav} active={location.pathname.includes(goTo)} />
                            ))
                        }
                        </NavSection>
                    }

                    {   (isAdmin || isSuperAdmin) &&
                        <NavSection lable="leads" showNav={showNav}>
                        {
                            routeList.leads.map(({name, goTo, icon})=>(
                                <NavItem key={goTo} goTo={goTo} name={name} icon={icon} showNav={showNav} active={location.pathname.includes(goTo)} />
                            ))
                        }
                        </NavSection>
                    }

                    {   (isAdmin || isSuperAdmin) &&
                        <NavSection lable="pages" showNav={showNav}>
                        {
                            routeList.pages.map(({name, goTo, icon})=>(
                                <NavItem key={goTo} goTo={goTo} name={name} icon={icon} showNav={showNav} active={location.pathname.includes(goTo)} />
                            ))
                        }
                        </NavSection>
                    }

                    {/* { !isStoreAdmin &&
                        <NavSection lable="users" showNav={showNav}>
                        {
                            isSuperAdmin ?
                            routeList.users.map(({name, goTo, icon})=>(
                                <NavItem key={goTo} goTo={goTo} name={name} icon={icon} showNav={showNav} active={location.pathname.includes(goTo)} />
                            ))
                                :
                            routeList.adminUsers.map(({name, goTo, icon})=>(
                                <NavItem key={goTo} goTo={goTo} name={name} icon={icon} showNav={showNav} active={location.pathname.includes(goTo)} />
                            ))
                        }
                        </NavSection>
                    } */}
                </div>
            </div>
        </div>
    );
};

export default Navbar;
