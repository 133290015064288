import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DetailSection from '../../../../../components/dashboard/common/detailSection/DetailSection';
import DetailSectionContent from '../../../../../components/dashboard/common/detailSection/DetailSectionContent';
import DetailSectionRow from '../../../../../components/dashboard/common/detailSection/DetailSectionRow';

import NoDataContainer from '../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../../../../../config';

import { CONTACT_ADDRESS_DELETED, REQUEST_ERROR } from '../../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';

const ContactUsAddressPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [contactAddresses, setContactAddresses] = useState([]);
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getContactAddressHandler = async () => {
        try {
            const getContactAddressReq = await axios.get(`${API_BASE_URL}contact-us/address`);
            console.log(getContactAddressReq.data);
            setContactAddresses(getContactAddressReq.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getContactAddressHandler();
    }, []);

    const deleteThisContactAddressHandler = async (id) => {
        try {
            const deleteThisContactAddressHandlerReq = await axios.delete(`${API_BASE_URL}contact-us/address/${id}`, config);
            console.log(deleteThisContactAddressHandlerReq.data);
            getContactAddressHandler();
            notifySuccess(CONTACT_ADDRESS_DELETED);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    return (
        <>
            <Section heading="Addresses" description={routeText} action={() => navigate("/dashboard/pages/contact-us")} actionLable="back" showAction={true}>
                <PlainContainer type="fullwidth">
                    <DetailSection lable="List of Openings" hasPrimaryAction={true} lablePrimaryAction={`create`} primaryAction={() => navigate("/dashboard/pages/contact-us/address/create")} hasSecondaryAction={false}>
                        <div style={{paddingTop: `20px`, paddingLeft: `20px`, paddingRight: `20px`}}>
                            {
                                contactAddresses.map(address => {
                                    return (
                                        <div style={{width: `100%`, float: `left`, marginBottom: `20px`}} key={address.id}>
                                            <DetailSection lable={address.city} hasPrimaryAction={true} lablePrimaryAction={`edit`} primaryAction={() => navigate(`/dashboard/pages/contact-us/address/edit/${address.id}`)} hasSecondaryAction={true} lableSecondaryAction={`delete`} secondaryAction={() => deleteThisContactAddressHandler(address.id)}>
                                                <DetailSectionContent hasPadding={true}>
                                                    <DetailSectionRow lable={`phone number`} value={address.phone_number} isImage={false} isIcon={false} hasChildren={false} />
                                                    <DetailSectionRow lable={`email ID`} value={address.email_id} isImage={false} isIcon={false} hasChildren={false} />
                                                    <DetailSectionRow lable={`address`} value={address.address} isImage={false} isIcon={false} hasChildren={false} />
                                                </DetailSectionContent>
                                            </DetailSection>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </DetailSection>
                </PlainContainer>
            </Section>
        </>
    )
}

export default ContactUsAddressPage