import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DetailSection from '../../../../../components/dashboard/common/detailSection/DetailSection';
import DetailSectionContent from '../../../../../components/dashboard/common/detailSection/DetailSectionContent';
import DetailSectionRow from '../../../../../components/dashboard/common/detailSection/DetailSectionRow';

import NoDataContainer from '../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../../../../../config';

import { PRODUCT_VARIANT_CREATION_FAILED, PRODUCT_VARIANT_DELETED, REQUEST_ERROR } from '../../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';

const HomeProductsPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [homeProducts, setHomeProducts] = useState({});
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getHomeProductsHandler = async () => {
        try {
            const getHomeProductsReq = await axios.get(`${API_BASE_URL}home/products`);
            console.log(getHomeProductsReq.data);
            setHomeProducts(getHomeProductsReq.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getHomeProductsHandler();
    }, []);

    return (
        <>
            <Section heading="products" description={routeText} action={() => navigate("/dashboard/pages/products")} actionLable="back" showAction={true}>
                <PlainContainer type="fullwidth">
                    <DetailSection lable="products" hasPrimaryAction={true} lablePrimaryAction={`edit`} primaryAction={() => navigate("/dashboard/pages/home/products/edit")} hasSecondaryAction={false}>
                        <DetailSectionContent hasPadding={true}>
                            <DetailSectionRow lable={`section heading`} value={homeProducts.section_heading} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`heading bold`} value={homeProducts.heading_bold} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`heading regular`} value={homeProducts.heading_regular} isImage={false} isIcon={false} hasChildren={false} />
                            <DetailSectionRow lable={`description`} value={homeProducts.description} isImage={false} isIcon={false} hasChildren={false} />
                        </DetailSectionContent>
                    </DetailSection>
                </PlainContainer>
            </Section>
        </>
    )
}

export default HomeProductsPage

