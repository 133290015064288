import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DetailSection from '../../../../../components/dashboard/common/detailSection/DetailSection';
import DetailSectionContent from '../../../../../components/dashboard/common/detailSection/DetailSectionContent';
import DetailSectionRow from '../../../../../components/dashboard/common/detailSection/DetailSectionRow';

import NoDataContainer from '../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../../../../../config';

import { PRODUCT_VARIANT_CREATION_FAILED, PRODUCT_VARIANT_DELETED, REQUEST_ERROR } from '../../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';

const GalleryImagesPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [galleryImages, setGalleryImages] = useState([]);
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getGalleryImagesHandler = async () => {
        try {
            const getGalleryImagesReq = await axios.get(`${API_BASE_URL}gallery/image`);
            setGalleryImages(getGalleryImagesReq.data);
            console.log(getGalleryImagesReq.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getGalleryImagesHandler();
    }, []);

    const deleteThisImage = async (item_id) => {
        console.log(item_id);
        try {
            const deleteImageReq = await axios.delete(`${API_BASE_URL}gallery/image/${item_id}`, config);
            getGalleryImagesHandler();
            LOGGER &&  console.log(deleteImageReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    return (
        <>
            <Section heading="Images" description={routeText} action={() => navigate("/dashboard/pages/gallery")} actionLable="back" showAction={true}>
                <PlainContainer type="fullwidth">
                    <DetailSection lable="Images" hasPrimaryAction={true} lablePrimaryAction={`add`} primaryAction={() => navigate("/dashboard/pages/gallery/images/create")} hasSecondaryAction={false}>
                        <div className="dashboardContentGallery">
                            {
                                galleryImages.map(function(image){
                                    return(
                                        <div className="dashboardContentGallerySingle" key={image._id}>
                                            <div className="dashboardContentGallerySingleImage">
                                                <img src={image.image_url.url} className="dashboardContentGallerySingleImg" />
                                            </div>
                                            <div className="dashboardContentGallerySingleAction">
                                                <div className="dashboardContentGallerySingleActionInner" onClick={()=>deleteThisImage(image.id)}>
                                                    <i className="fas fa-trash-alt dashboardContentGallerySingleActionIco"></i>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </DetailSection>
                </PlainContainer>
            </Section>
        </>
    )
}

export default GalleryImagesPage