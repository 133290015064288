import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import PlainContainer from '../../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../../components/general/containers/Section';
import Form from '../../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../../components/general/forms/TextInput';
import FileInput from '../../../../../../../components/general/forms/FileInput';
import TextArea from '../../../../../../../components/general/forms/TextArea';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../../utils/toastify';
import { REQUEST_ERROR, OPENING_CREATED } from '../../../../../../../utils/messages';

const EditHomePartnerPortalPage = () => {
    let navigate = useNavigate();
    const location = useLocation();
    let { portal_id } = useParams(); 

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [image, setImage] = useState('');
    const [imageID, setImageID] = useState('');

    const [isNameError, setIsNameError] = useState(false);
    const [isUrlError, setIsUrlError] = useState(false);
    const [isImageError, setIsImageError] = useState(false);

    const [nameErrorTxt, setNameErrorTxt] = useState("");
    const [urlErrorTxt, setUrlErrorTxt] = useState("");
    const [imageErrorTxt, setImageErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const [showImagePreview, setShowImagePreview] = useState(false);

    const getThisPortalHandler = async () => {
        try {
            const getThisPortalReq = await axios.get(`${API_BASE_URL}home/partner-portal/portal/${portal_id}`);
            console.log(getThisPortalReq.data);
            setName(getThisPortalReq.data.name);
            setUrl(getThisPortalReq.data.url);
            setImage(getThisPortalReq.data.image_url.url);
            setImageID(getThisPortalReq.data.image_url.id);
            setShowImagePreview(true);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getThisPortalHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(name == ''){
            setNameErrorTxt('Name is required');
            setIsNameError(true);
            return;
        }
        else{
            setNameErrorTxt('');
            setIsNameError(false);
        }

        if(url == ''){
            setUrlErrorTxt('Link is required');
            setIsUrlError(true);
            return;
        }
        else{
            setUrlErrorTxt('');
            setIsUrlError(false);
        }

        if(imageID == ''){
            setImageErrorTxt('Image is required');
            setIsImageError(true);
            return;
        }
        else{
            setImageErrorTxt('');
            setIsImageError(false);
        }

        const data = {
            name: name,
            url: url,
            image: imageID
        }

        try {
            const updatePortalReq = await axios.patch(`${API_BASE_URL}home/partner-portal/portal/${portal_id}`, data, config);
            notifySuccess(OPENING_CREATED);
            navigate('/dashboard/pages/home/partner-portals/portals');
            LOGGER &&  console.log(updatePortalReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="Portals" description={routeText} action={() => navigate("/dashboard/pages/home/partner-portals")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="name" isMandatory={true} isError={isNameError} error={nameErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={name} placeholder="Please Enter The Name" onChange={(e) => setName(e.target.value)} />
                            </TextInput>
                            <TextInput lable="link" isMandatory={true} isError={isUrlError} error={urlErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={url} placeholder="Please Enter The Link" onChange={(e) => setUrl(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <FileInput lable="logo" isMandatory={true} value={imageID} preview={image} showPreview={showImagePreview} onchange={(data) => {setImageID(data); LOGGER &&  console.log(data)}} isError={isImageError} error={imageErrorTxt} reset={resetAttachment} />
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditHomePartnerPortalPage