import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { PHONE_NUMBER_UPDATED, REQUEST_ERROR } from '../../../../../../utils/messages';

const EditFooterPhoneNoPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    let { phone_id } = useParams();

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [lable, setLable] = useState('');
    const [phoneNo, setPhoneNo] = useState('');

    const [isLableError, setIsLableError] = useState(false);
    const [isPhoneNoError, setIsPhoneNoError] = useState(false);

    const [lableErrorTxt, setLableErrorTxt] = useState("");
    const [phoneNoErrorTxt, setPhoneNoErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const getThisFooterPhoneNoHandler = async () => {
        try {
            const getThisFooterPhoneNoReq = await axios.get(`${API_BASE_URL}footer/phone-numbers/${phone_id}`);
            console.log(getThisFooterPhoneNoReq);
            setLable(getThisFooterPhoneNoReq.data.lable);
            setPhoneNo(getThisFooterPhoneNoReq.data.number);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getThisFooterPhoneNoHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(lable == ''){
            setLableErrorTxt('Lable is required');
            setIsLableError(true);
            return;
        }
        else{
            setLableErrorTxt('');
            setIsLableError(false);
        }

        if(phoneNo == ''){
            setPhoneNoErrorTxt('Phone No. is required');
            setIsPhoneNoError(true);
            return;
        }
        else{
            setPhoneNoErrorTxt('');
            setIsPhoneNoError(false);
        }

        const data = {
            lable: lable,
            number: phoneNo
        }

        console.log(data);

        try {
            const updateFooterPhoneNoReq = await axios.patch(`${API_BASE_URL}footer/phone-numbers/${phone_id}`, data, config);
            notifySuccess(PHONE_NUMBER_UPDATED);
            navigate('/dashboard/general/footer/phone-numbers');
            LOGGER &&  console.log(updateFooterPhoneNoReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="phone number" description={routeText} action={() => navigate("/dashboard/general/footer/phone-numbers")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <SplitContainer type="fullwidth">
                            <TextInput lable="lable" isMandatory={true} isError={isLableError} error={lableErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={lable} placeholder="Please Enter The Lable" onChange={(e) => setLable(e.target.value)} />
                            </TextInput>
                            <TextInput lable="phone no." isMandatory={true} isError={isPhoneNoError} error={phoneNoErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={phoneNo} placeholder="Please Enter The Phone No." onChange={(e) => setPhoneNo(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditFooterPhoneNoPage